import React from 'react'
import { graphql } from 'gatsby'

import Body from 'components/fixtures/body'
import { Layout, SubHeader } from 'components/global'

const Fixtures = ({ data }) => {
  const metaData = {
    title: 'Tickets | Arnold Clark Cup',
  }
  const { fixtures } = data.Fixtures.nodes[0]
  return (
    <Layout title={metaData.title} $noAnimation>
      <SubHeader title="Tickets" />
      <Body data={fixtures} />
    </Layout>
  )
}

export default Fixtures

export const FixtureQuery = graphql`
  query MyQuery {
    Fixtures: allFixturesJson {
      nodes {
        fixtures {
          date
          stadium
          matches {
            match
            kickoff
          }
          text
          findoutmore
          purchase
          hospitality
          secondPurchase
          disclaimer
          images {
            desktop {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
